.Hook{
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 100%;
    
    flex-wrap: wrap;


    .container-slideIn{
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .animation-gif{
        position: absolute;
        top: 25%;
        left: 50%;
        width: 600px;
        transform: translate(-50%, -50%);
        max-width: 800px;
        min-width: 400px;
    }

    .title-container{
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%);
        z-index: 2;
        width: max-content;

        .title{
            font-family: "JetBrains Mono", monospace;
            font-size: 90px;
            font-weight: 800;
            z-index: 1;
            line-height: 100%;
            letter-spacing: -8px;
            word-break: keep-all;
            line-break: strict;
            
        }
    }

    .description-container{
        display: flex;
        z-index: 2;
        justify-content: flex-start;
        align-items: center;
        height: 50%;
        flex: 1;
        align-self: self-end;
        box-sizing: border-box;
        margin: 50px;

        .description{
            font-weight: 400;
            line-height: 1.1;
            font-size: 35px;
            font-family: "Poppins", serif;
            max-width: 80%;
            white-space: normal;
            color: #FFF;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .container-donnees{
                display: flex;
                justify-content: space-between;
                .donnees{
                    display: flex;
                    flex-direction: column;
                    
                    .chiffre{
                        font-size: 70px;
                        font-weight:600 ;
                    }
    
                    .libelle{
                        font-size: 25px;
                        color: #777;
                    }
                }
            }
            

            .buttons{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                font-size: 22px;

                .click{
                    color: #777;
                }

                .button-contact{
                    background-color: #EEE;
                    color: #000;
                    padding-inline: 20px;
                    padding-block: 10px;
                    border-radius: 8px;
                    cursor: pointer;
                    width: 350px;
                    text-align: center;

                    .libelle-contact{
                        display: none;
                    }

                    .active{
                        display: block;
                    }
                }

                .button-contact:hover{
                    opacity: 0.8;
                }
        
                .button-projects{
                    background-color: #222;
                    color: #EEE;
                    padding-inline: 20px;
                    padding-block: 10px;
                    border-radius: 8px;
                    cursor: pointer;
                }

                .button-projects:hover{
                    opacity: 0.8;
                }
            }
        }  
    }

    .popUp-contact{
        position: fixed;
        background-color: #222;
        height: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;
        width: 50%;

        .mail{
            .copy{
                height: 25px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .Hook{
        .animation-gif{}

        .title-container{
            .title{
                font-size: 80px;
            }
        }
    }
}

@media (max-width: 800px) {
    .Hook{     
        min-height: 100vh;
        .container-slideIn{
        }

        .animation-gif{
        }

        .title-container{
            width: min-content;
            .title{
                font-size: 70px;
            }
        }

        .description-container{
            margin: unset;
            margin-block: 20px;

            .description{
                font-size: 25px;
                max-width: 100%;

                .container-donnees{
                    display: flex;
                    justify-content: space-between;
                    .donnees{
                        display: flex;
                        flex-direction: column;
                        
                        .chiffre{
                            font-size: 40px;
                            font-weight:600 ;
                        }
        
                        .libelle{
                            font-size: 18px;
                            color: #777;
                        }
                    }
                }

                .buttons{
                    font-size: 18px;
                    .button-contact{
                        width: 250px;
                    }
            
                    .button-projects{
                    }
                }
            }  
        }
    }
}