:root {
  /*Thème*/
  --bg-color: #000;
  --text-color: #ffffff;
  --primary-color: #1e90ff;

  
}

[data-theme="dark"] {
  --bg-color: #000;
  --text-color: #ffffff;
  --primary-color: #1e90ff;
}

[data-theme="light"] {
  --bg-color: #ffffff;
  --text-color: #000000;
  --primary-color: #007bff;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Suisse, sans-serif;
  font-weight: 400;
  font-size: 16px;

  background-color: var(--bg-color);
  color: var(--text-color);
  
}

a{
  text-decoration: none;
  color: unset;
}


@media (max-width: 800px) {
  body {
    font-size: 14px;
  }
}