
    /* Conteneur scrollable */
.homepage {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #000;
    position:relative ;
    z-index: 2;

    .container-homepage{
        width: 1000px;
        box-sizing: border-box;
        padding-inline: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        color: #EEE;
    }
}

@media (max-width: 1000px) {
    .homepage {
    
        .container-homepage{
            width: 100%;
        }
    }
}





