.header{ 
    z-index: 10;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    color: var(--color-neutral);
    opacity: 0.9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 25px;

        .name{
            font-size: 20px;
            text-align: start;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            .logo{
                height: 30px;
            }
        }
        .container-menu{
            display: flex;
            gap: 50px;

            .item{
                cursor: pointer;
            }

            .item:hover{
                color: var(--color-contrast);
            }
        }
    
}

@media (max-width: 800px) {
    .header{
        .header-container{
            .container-menu{ 
                gap: 20px;           
            }
        }
    }
}