.Project{
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;

    .bg{
        width: 100%;
        height: 100%;
        opacity: 0.1;
        filter: saturate(0);
        transition: opacity 1s, transform 1s, filter 1s;
    }

    .mask{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .title-light{
            color: whitesmoke;
        }

        .title-dark{
            color: #222;
        }

        .title{
            
            font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
            font-size: 40px;
            transition: opacity 1s;
        }
    }
}

.dark{
    background-color: #222;
}

.light{
    background-color: #CCC;
}

.Project:hover .bg{
    filter: saturate(1);
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1);
}

.Project:hover .title{
    opacity: 0;
}