.TypeingText::after {
    content: "|";
    animation: blink 0.7s infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}
