.language-picker {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;

    .icon-language{
        height: 22px;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style: none;
    padding: 0;
    margin: 5px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 120px;
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s;
}

.dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.2);
}
  