.Projects{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-block: 50px;
    width: 100%;
    box-sizing: border-box;
    margin-block: 50px;

    .container-slideIn{
        overflow: hidden;
        display: flex;
        flex-direction: column;  
    }

    .container-project{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .title{
        color: #FFF;
        font-size: 60px;
        font-weight: 500;
        font-family: "Poppins", serif;
    }
}

@media (max-width: 1000px) {
    .Projects{
    
        .container-project{
        }
    
        .title{
            font-size: 40px;
        }
    }
    
}