.Introduction{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    .mask{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        //animation: translate 1.5s linear forwards;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(3px);
    }

    

    .container-image{
        display: grid;
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .top-left{
            background-image: url('../../assets/home-image_1.webp');
            background-position: right bottom;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .top-right{
            background-image: url('../../assets/home-image_2.webp');
            background-position: left bottom;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .bottom-left{
            background-image: url('../../assets/home-image_3.webp');
            background-position: right top;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .bottom-right{
            background-image: url('../../assets/home-image_4.webp');
            background-position: left top;
            background-size: cover;
            background-repeat: no-repeat;
        }

    }

    .container-text{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        pointer-events: none;
        gap: 15px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .titre{
            color: whitesmoke;
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            font-size: 120px;
            z-index: 2;
        }
        .desc{
            font-size: 20px;
            color: #BBB;
            z-index: 2;
            text-align: center;
        }

        .decouvrir{
            font-size: 25px;
            color: #111;
            cursor: pointer;
            position: relative;
            transition: opacity 200ms ease;
            pointer-events: auto;
            background-color: #CCC;
            padding-inline: 15px;
            padding-block: 5px;
            opacity: 0.7;
            z-index: 2;
            border-radius: 8px;
        }

        .decouvrir:hover{
            opacity: 0.9;
        }
    }
}


@media (width <= 1000px) {
    .Introduction{
        .container-text{
            .titre{
                font-size: 90px;
                max-width: 90%;
            }   
        }
        
    }
}

@media (width <= 800px) {
    .Introduction{
        .container-text{
            .titre{
                font-size: 50px;
                max-width: 90%;
            }   
        }
        
    }
}